import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useOIDCCallback } from '@/hooks/useOIDCCallback'
import { useLocalStorage } from '@/utils/localstorage/localstorage'
import { redirectToLogin, validateSession } from '@/services/auth'

import ComponentPreview from '../views/ComponentPreview.vue'
import OneView from '@/views/OneView.vue'

const { getStorage, removeStorage } = useLocalStorage()

export const routeNames = {
  oneView: 'OneView',
}

const activeSSO = process.env.VUE_APP_ENV !== "development"


export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: { template: '', beforeCreate() { this.$router.push({ name: routeNames.oneView }) } }
  },
  {
    path: '/app',
    component: OneView,
    name: routeNames.oneView
  },
  {
    path: '/app/preview',
    component: ComponentPreview
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {

  function redirect() {
    next({
      path: getStorage("before-route-path") as string,
      replace: true
    })

    removeStorage("before-route-path")
  }

  if (activeSSO) {

    if (to.query.code) {
      useOIDCCallback(to.query.code as string, redirect);
      return
    }

    if (!validateSession()) {
      redirectToLogin(to.fullPath);
    }

  }

  next()
})


export default router
