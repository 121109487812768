import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card__one_app bg-surface-variant" }
const _hoisted_2 = { class: "card__one_app_hero" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "d-flex align-center ga-2 text-cs_surface_container_lowest" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "card__one_app_content d-flex flex-column justify-center px-6" }
const _hoisted_7 = { class: "card__one_app_content_title_text" }
const _hoisted_8 = { class: "card__one_app_content_description_text" }

import imgDefault from "@/assets/backgrounds/transaction-desktop-light.png";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'CardOneApp',
  props: {
    img: { default: imgDefault },
    app: {},
    icon: {}
  },
  setup(__props: any) {

const { t } = useI18n();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.img,
        alt: "background"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          src: _ctx.icon,
          alt: "icon"
        }, null, 8, _hoisted_5)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.app.label), 1),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)(_ctx.app.description || "")), 1)
    ])
  ]))
}
}

})