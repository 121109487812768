import { oidcCallback } from "@/services/auth";
import { useLocalStorage } from "@/utils/localstorage/localstorage";
import { useRouter } from "vue-router";

const { removeStorage, setStorage } = useLocalStorage()

export async function useOIDCCallback(code = '', redirect: () => void) {
  const router = useRouter()
  if (!code) {
    router.replace("/one");
    return
  }

  removeStorage("token");
  setStorage("code", code);

  const response = await oidcCallback(code);
  const eventAddAccessToken = new CustomEvent('access-token');
  if (response?.access_token) {
    setStorage("token", JSON.stringify(response));
    dispatchEvent(eventAddAccessToken);

    removeStorage("code");

    const url = new URL(window.location.href);
    url.searchParams.delete('code');
    window.history.replaceState({}, document.title, url.toString());
    redirect()
  }
}
