import { setupWorker } from 'msw/browser'
import { handlersHTTP } from './server/handlersHTTP'

export const worker = setupWorker(...handlersHTTP)


export async function enableMocking() {
  if (!process.env.VUE_APP_ACTIVE_MOCK || process.env.VUE_APP_ACTIVE_MOCK === "false") {
    return
  }

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}
