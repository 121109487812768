<template>
  <div class="card__one_app bg-surface-variant">
    <div class="card__one_app_hero">
      <img :src="img" alt="background" />
      <div class="d-flex align-center ga-2 text-cs_surface_container_lowest">
        <img :src="icon" alt="icon"/>
      </div>
    </div>
      <div class="card__one_app_content d-flex flex-column justify-center px-6">
      <div class="card__one_app_content_title_text">{{ app.label }}</div>
      <div class="card__one_app_content_description_text">
        {{ t(app.description || "") }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import imgDefault from "@/assets/backgrounds/transaction-desktop-light.png";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
withDefaults(defineProps<{ img?: string; app: NewMenu.Link; icon?: string }>(), {
  img: imgDefault,
});
</script>

<style lang="scss" scoped>
.card__one_app {
  width: 320px;
  display: block;
  flex: 0 0 auto;
  border-radius: 28px;

  &_hero {
    height: 180px;
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 28px;

    div {
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 200px;
      }
    }
  }

  &_content {
    height: 104px;
    display: block;

    &_title_text {
      font-size: var(--v-typescale-headline-medium-size);
      line-height: var(--v-typescale-headline-medium-line-height);
    }

    &_description_text {
      font-size: var(--v-typescale-body-large-size);
      line-height: var(--v-typescale-body-large-line-height);
    }
  }
}
</style>
