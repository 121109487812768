<template>
  <header>
    <div
      class="d-flex align-center ga-8 w-100 ma-auto px-4"
      style="position: relative"
      id="menu"
    >
      <slot name="logo" v-if="showLogo">
        <img :src="imgClearsale" alt="Clearsale Logo" height="48px" />
      </slot>
      <div class="d-flex align-center justify-space-between w-100">
        <div>
          <slot> <div></div> </slot>
        </div>
        <nav>
          <slot name="nav">
            <div class="d-flex align-center">
              <slot name="links">
                <AppsMenu v-model="appsMenu" />
                <UserMenu v-model="userMenu" />
              </slot>
            </div>
          </slot>
        </nav>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import imgClearsale from "@/assets/logo_mobile.png";
import UserMenu from "./UserMenu/UserMenu.vue";
import AppsMenu from "./AppsMenu/AppsMenu.vue";
import { ref, watch } from "vue";

withDefaults(defineProps<{ showLogo?: boolean }>(), { showLogo: true });
const appsMenu = ref(false);
const userMenu = ref(false);

watch(appsMenu, () => {
  if (appsMenu.value) {
    userMenu.value = false;
  }
});

watch(userMenu, () => {
  if (userMenu.value) {
    appsMenu.value = false;
  }
});
</script>
