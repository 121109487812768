<template>
  <div :class="{ 'px-4': !mdAndUp }">
    <HeroOne></HeroOne>
  </div>
  <div style="flex: 1" class="d-flex flex-column">
    <div class="apps__title_text ml-8 mt-8">{{ t(label.AppsPage.Apps.title) }}</div>
    <div class="d-flex overflow-auto" style="flex: 1">
      <div>
        <div
          class="d-flex overflow-x-auto mt-4 flex-nowrap hide-scrollbar pr-4"
          :style="{
            gap: mdAndUp ? '1.5rem' : '1rem',
          }"
          ref="scrollContainer"
          style="max-width: 1040px; overflow: hidden"
        >
          <div v-if="!mdAndUp"></div>
          <CardOneApp
            v-for="app in renderApps"
            :key="app.routerName"
            :app="app"
            :icon="appIcons[app.routerName as keyof typeof appIcons]"
            @click.stop="handleClick(app, $event)"
            :img="selectBackgroundByTheme[themeName as Theme_Type][app.routerName as AppView_Type] "
          ></CardOneApp>
        </div>
      </div>
    </div>
    <div
      class="mt-4"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0rem;
      "
    >
      <img style="width: 24px" :src="imgFooter" alt="imgClearsaleOptions.alt" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";

import imgFooter from "@/assets/logo_mobile.png";
import HeroOne from "@/components/one/HeroOne/HeroOne.vue";
import CardOneApp from "@/components/one/CardOneApp/CardOneApp.vue";
import { useOneStore } from "@/store/OneStore";
import { useRouter } from "vue-router";
import { useDisplay, useTheme } from "vuetify/lib/framework.mjs";

import NexusViewDarkBG from "@/assets/backgrounds/BG-nexus-dark.jpg";
import NexusViewLightBG from "@/assets/backgrounds/BG-nexus-light.jpg";
import ShieldViewDarkBG from "@/assets/backgrounds/BG-shield-dark.jpg";
import ShieldViewLightBG from "@/assets/backgrounds/BG-shield-light.jpg";
import NexusLogoWhite from "@/assets/icons/nexus-logo-white.svg";
import ShieldLogoWhite from "@/assets/icons/shield-logo-white.svg";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const { t } = useI18n();
const { $state } = useOneStore();
const router = useRouter();
const { mdAndUp } = useDisplay();
const { name: themeName } = useTheme();

const appIcons = {
  ShieldView: ShieldLogoWhite,
  NexusView: NexusLogoWhite,
};

type Theme_Type = "light" | "dark";

type AppView_Type = "NexusView" | "ShieldView";

type SelectBackground = {
  [ThemeKey in Theme_Type]: {
    [AppViewKey in AppView_Type]: string;
  };
};

type SelectRouter = {
  [appKey in AppView_Type]: string;
};

const routerMap: SelectRouter = {
  NexusView: "/nexus",
  ShieldView: "/shield",
};

const selectBackgroundByTheme: SelectBackground = {
  light: {
    NexusView: NexusViewLightBG,
    ShieldView: ShieldViewLightBG,
  },
  dark: {
    NexusView: NexusViewDarkBG,
    ShieldView: ShieldViewDarkBG,
  },
};

const scrollContainer = ref<HTMLElement | null>(null);
const isDragging = ref(false);
let mouseIsDown = false;
let startX: number;
let scrollLeft: number;

const renderApps = computed(() => {
  return $state.apps.filter((app) => app.routerName !== "OneView");
});

const handleMouseDown = (e: MouseEvent) => {
  mouseIsDown = true;
  isDragging.value = false;
  if (!scrollContainer.value) return;

  startX = e.pageX - scrollContainer.value.offsetLeft;
  scrollLeft = scrollContainer.value.scrollLeft;
};

const handleMouseLeave = () => {
  mouseIsDown = false;

  if (!scrollContainer.value) return;

  scrollContainer.value.classList.remove("active");
};

const handleMouseUp = () => {
  mouseIsDown = false;

  if (!scrollContainer.value) return;

  scrollContainer.value.classList.remove("active");
};

const handleMouseMove = (e: MouseEvent) => {
  if (!mouseIsDown) return;
  e.preventDefault();

  if (!scrollContainer.value) return;

  scrollContainer.value.classList.add("active");
  const xOffsetContainer = e.pageX - scrollContainer.value.offsetLeft;
  const walk = (xOffsetContainer - startX) * 1;
  scrollContainer.value.scrollLeft = scrollLeft - walk;
  isDragging.value = true;
};

const addEventListeners = () => {
  const container = scrollContainer.value;

  if (!container) return;

  container.addEventListener("mousedown", handleMouseDown);
  container.addEventListener("mouseleave", handleMouseLeave);
  container.addEventListener("mouseup", handleMouseUp);
  container.addEventListener("mousemove", handleMouseMove);
};

const removeEventListeners = () => {
  const container = scrollContainer.value;

  if (!container) return;

  container.removeEventListener("mousedown", handleMouseDown);
  container.removeEventListener("mouseleave", handleMouseLeave);
  container.removeEventListener("mouseup", handleMouseUp);
  container.removeEventListener("mousemove", handleMouseMove);
};

onMounted(() => {
  addEventListeners();

  // console.log("TEST:::: ", mountParcel);
});

onUnmounted(() => {
  removeEventListeners();
});

function handleClick(item: NewMenu.Link, event: MouseEvent) {
  if (isDragging.value) {
    event.preventDefault();
    event.stopImmediatePropagation();
  } else {
    if (item.routerName)
      router.push(routerMap[item.routerName as AppView_Type]);
  }
}
</script>

<style lang="scss" scoped>
.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.apps__ {
  &title_text {
    font-size: var(--v-typescale-display-small-size);
    line-height: var(--v-typescale-display-small-line-height);
  }
}

.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}
</style>
