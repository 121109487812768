export const label = {
  AppsPage: {
    Hero: {
      topMessage: "One step ahead_",
      title: "One ClearSale",
      subtitle: "All your Fraud Protection solutions in one place"
    },
    Apps: {
      title: "Your apps"
    }
  },
  SearchBar: {
    placeholder: "Search on ClearSale"
  },
  UserMenu: {
    language: {
      title: "Language",
      english: "English",
      portuguese: "Português",
      spanish: "Español"
    },
    timezone: "Timezone",
    theme: {
      title: "Theme",
      dark: "Dark",
      light: "Light"
    },
    signout: "Sign Out"
  },
  Shield: {
    AppCard: {
      subtitle: "Intelligence in fraud analysis"
    }
  },
  Nexus: {
    AppCard: {
      subtitle: "Fraud analysis visualization"
    }
  }
}