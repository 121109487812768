import { defineStore } from "pinia";

export const useOneStore = defineStore('store-one', {
  state: () => ({
    apps: [] as NewMenu.Link[]
  }),
  actions: {
    loadApps(apps: NewMenu.Link[]) {
      this.apps = apps
    }

  },
})


