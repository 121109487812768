<template>
  <div v-click-outside="handleCloseMenu">
    <v-btn
      icon
      variant="text"
      color="cs_surface_variant"
      id="menu-activator"
      @click="modelMenu = !modelMenu"
    >
      <v-icon icon="mdi-apps" class="text-cs_on_surface_variant"></v-icon>
    </v-btn>
    <Teleport to="body">
      <Transition name="fade">
        <div v-if="modelMenu" class="cs__overlay"></div>
      </Transition>
      <Transition name="open">
        <div
          v-if="modelMenu"
          class="cs__menu d-flex flex-column flex-wrap ga-2 bg-surface-variant"
          style="border-radius: 19px 0px 0px 16px; z-index: 9999"
        >
          <div
            class="d-flex justify-end px-4 ga-4 cursor-pointer"
            style="min-width: 216px"
          >
            <v-btn
              icon
              class="mt-2"
              style="
                font-size: var(--v-typescale-body-medium-size);
                font-weight: normal;
              "
              color="on-surface-variant"
              variant="text"
            >
              <v-icon icon="mdi-menu-open" size="24"></v-icon>
            </v-btn>
          </div>
          <template v-for="app in dataAppsList" :key="app.label">
            <div
              class="d-flex pl-8 pa-3 ga-4 cursor-pointer"
              :class="`order-${app.order}`"
              style="min-width: 216px"
              @click="handleRouterTo(app)"
            >
              <img
                :src="app.img ?? imgClearsale"
                :alt="`${app.label} Logo`"
                height="32px"
              />

              <h4
                class="mt-2 text-on-surface-variant"
                style="
                  font-size: var(--v-typescale-body-medium-size);
                  font-weight: normal;
                "
              >
                {{ app.label }}
              </h4>
            </div>
          </template>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { vClickOutside } from "@/components/one/NewMenu/directives/clickOutside";
import imgClearsale from "@/assets/logo_mobile.png";
import { appsExample } from "../constants/AppsLinks";
import { useCustomMenu } from "../useCustomMenu";

const props = withDefaults(defineProps<{ links: NewMenu.Link[] }>(), {
  links: () => appsExample,
});

const emits = defineEmits<{
  (e: "routerTo", routeApp: NewMenu.Link): void;
  (e: "selectLink", link: NewMenu.Link): void;
}>();

const dataAppsList = ref(props.links);

const modelMenu = defineModel({ default: false });

const { handleRouterTo, handleCloseMenu } = useCustomMenu({
  emits,
  props,
  model: modelMenu,
});
</script>

<style scoped>
.cs__menu {
  max-width: 318px;
  height: 100vh;
  position: fixed;
  z-index: 999;

  top: 0px;
  transition: all 0.3s;
  opacity: 1;
  right: 0px;
}

.cs__overlay {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  background: rgb(var(--v-theme-scrim));
  opacity: 0.5;
  z-index: 999;
}

.open-enter-active,
.open-leave-active {
  transition: all 0.5s ease;
}

.open-enter-from,
.open-leave-to {
  right: -318px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
